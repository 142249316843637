/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Container, Text, Image } from 'theme-ui';
import SectionHeading from 'components/section-heading';

const WhyChooseUs = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulFeature {
      textFeature {
        heading
        headingHighlight
        description {
          description
        }
      }
      cards {
        title
        description {
          description
        }
        icon {
          file {
            fileName
            url
          }
        }
      }
    }
  }
`)
  const text = data.contentfulFeature.textFeature;
  const card = data.contentfulFeature.cards;

  return (
    <Box as="section" sx={styles.features} id="service">
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.leftContent}>
          {card.map(({ title, description, icon }, i) => (
            <Box sx={styles.featureItem} key={i}>
              <Image src={icon.file.url} loading="lazy" alt={icon.file.fileName} />
              <Text as="p" sx={styles.title}>
                {title}
              </Text>
              <Text className="cardDesc">
                {description.description}
              </Text>
            </Box>
          ))}
          </Box>
          <Box sx={styles.rightContent}>
            <SectionHeading
              sx={styles.heading}
              slogan="Our Services"
              title={text.heading}
              description={text.description.description} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyChooseUs;
const styles = {
   features: {
    py: [8, null, 9, null, null, 10],
    backgroundColor: 'secondary',
    position: 'relative',
    fontFamily: 'heading',
  },
  contentWrapper: {
    gap: [20, 30, 30, 40, 45, 60, 70, 80],
    display: ['flex', 'flex', 'flex','flex', 'grid'],
    flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'unset'],
    gridTemplateColumns: [
      '1.2fr 0.8fr',
      '1.2fr 0.8fr',
      '1.2fr 0.8fr',
      '1.1fr 0.9fr',
      '1.1fr 0.9fr',
      '1.1fr 0.9fr',
      '1.2fr 0.8fr',
    ],
    alignItems: 'center',
  },
  leftContent: {
    gap: [20, 20, 20, 20, 30, 45],
    display: ['contents', 'grid'],
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'flex-start',
    '> div': {
      ':first-of-type': {
        mt: ['0','65px', '65px','0','65px','65px','65px'],
      },
      ':last-of-type': {
        mt: ['0','-65px','-65px','0','-65px','-65px', '-65px'],
      },
    },
  },
  rightContent: {
    '@media screen and (max-width: 1024px)': {
      paddingLeft:'10px',
    },
    '.heading':{
      // paddingBottom:'25px'
    }
  },
  heading: {
    textAlign: 'left',
    mb: ['20px'],
    mt: [0, 0, '-70px'],
    h2: {
      fontSize: ['28px', '28px', '28px', '36px', '36px', '38px'],
      lineHeight: '1.2',
      letterSpacing: '-1.5px',
      br: {
        display: ['none', 'none', 'none', 'block'],
      },
    },
    p: {
      mt: ['15px', '10px'],
    },
  },
  listItem: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 2.81,
    display: 'flex',
    alignItems: 'center',
    img: {
      mr: '10px',
    },
  },
  featureItem: {
    backgroundColor: '#fff',
    boxShadow: '0px 25px 100px rgb(69 88 157 / 0%)',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '.cardDesc':{
      fontSize:'14px',
      color:'text',
      lineHeight:'heading',
    },
    padding:'20px',
    textAlign: 'center',
    minHeight: [150, 150, 150, 185, '240px', 280, 320],
    width: ['100%', 'auto', '350px', '350px', 250, 258, 300],
    height: ['360px', 'auto', '350px', '350px', 'auto', 'auto', 'auto'],

  },
  value: {
    fontWeight: 700,
    fontSize: [30, 30, 30, 30, 50, 60, 72],
    lineHeight: [1.83, 0.76],
    letterSpacing: '-1.5px',
  },
  title: {
    fontSize: ['14px', '16px', '16px', '15px', '16px'],
    lineHeight: [1.62, 1.48],
    letterSpacing: '-0.2px',
    mt: ['1px', '20px'],
    fontWeight:'600',
    marginBottom:'12px',
    color:'heading'
  },
};