   /** @jsx jsx */
import { jsx, Box, Container, Image, Flex, Grid } from 'theme-ui';
import { Link } from "gatsby"
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';

import arrow from 'assets/arrow.png'

const Gallery = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProducts {
        productCards {
          slug
          name
          type
          image {
            file {
              fileName
              url
            }
          }
        }
      },
      contentfulGallery {
        textFeature {
          heading
          sectionName
        }
      }
    }
  `)
    
  const posts = data.contentfulProducts.productCards;
  const postLimit = posts.slice(-6).reverse();
  const text = data.contentfulGallery.textFeature;

  return (
    <Box id="products" as="section" sx={styles.section}>
      <Container sx={styles.container}>
         <Box className="wrap">
            <SectionHeading
              sx={styles.heading}
              slogan={text.sectionName}
              title={text.heading} 
              className="headingSection"/>
              <Link sx={styles.loadMore} to="/product-Lists">
                <Box className="more" title="see more products">More Produtcs</Box>
                <Image src={arrow} loading="lazy" alt="arrow" className="arrow"/>
              </Link>
          </Box>
          <Flex sx={styles.galleryWrapper}>
            <Grid columns={[1, 2, 2, 3, 3, 3]}>
            {postLimit.map(({image, name, slug },) => (
              <Link to={`/product-Lists/${slug}`}  key={slug} sx={styles.containerWrapper}>
                <Image src={image.file.url} alt={image.file.fileName} loading="lazy"/>
                <Box as="figcaption">{name}</Box>
              </Link>  
            ))}
            </Grid>
          </Flex>
      </Container> 
    </Box>
  );
};

export default Gallery;
const styles = {
  section:{
    paddingTop: [8, null, 9, null, null, 10],
    paddingBottom:'60px',
    fontFamily: 'heading',
    '.wrap':{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    a:{
      color: 'primary',
      fontSize:'15px',
      fontWeight: 'body',
      lineHeight: 1.2,
      textDecoration:'none',
      marginRight:'6px',
      cursor: 'pointer',
      ':hover': {
        color: 'primary',
      },
    }
  },
  containerWrapper:{
    position:'relative',
    textAlign: 'center',
    color:'#fff',
    ':before': {
      background: `linear-gradient(1.15deg, #020718 0.72%, rgba(255, 255, 255, 0) 41.15%)`,
      borderRadius: 5,
      opacity: 0.7,
      content: `''`,
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      zIndex: 1,
      ':hover': {
        img: {
          transform: 'scale(1.2)',
        },
      },
    },
    img:{
      borderRadius: '7px',
      height:'100%',
      objectFit: 'cover',
      transform: 'scale(1)',
      transition: 'all 0.3s ease-in-out 0s',
    },
    figcaption:{
      position: 'absolute',
      bottom: '8px',
      left: '16px',
      color:'#fff',
      fontWeight: 'semi-bold',
      fontSize: 18,
      lineHeight: 1.68,
      letterSpacing: 'heading',
      zIndex: 1,

    },
  },
  loadMore:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    img:{
      width:'12px'
    }
    
  },
  figure: {
    borderRadius: 5,
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'flex-start',
    mb: 30,
    mx: 15,
  
  },
  heading: {
    textAlign: 'left',
    h2: {
      fontSize: ['28px', '28px', '28px', '36px', '36px', '38px'],
      lineHeight: '1.2',
      letterSpacing: '-1.5px',
      textAlign:'left',
      br: {
        display: ['none', 'none', 'none', 'block'],
      },
    },
    p:{
      textAlign:'left'
    },
    '.headingSection':{
      display:'none',
      mr:'auto',
      ml:'6'
    },
 
  },
  
  button: {
    minHeight: [50, 50, 50, 60],
    fontSize: [14, 14, 16],
    width: '100%',
    svg: {
      transition: 'margin-left 0.3s ease-in-out 0s',
    },
    ':hover': {
      svg: {
        ml: '5px',
      },
    },
  },
}
