/** @jsx jsx */
import { jsx, Box, Heading, Text } from 'theme-ui';

const SectionHeading = ({ slogan, more, title, description, ...props }) => {
  return (
    <Box sx={styles.heading} {...props}>
      {slogan && (
        <Text sx={styles.slogan} as="p" variant="title">
          {slogan} 
        </Text>
       )}
        <Heading sx={styles.title} variant="heroPrimary">{title}</Heading>
        <Text as="p" sx={styles.description} >
          {description}
        </Text>
    </Box>
  );
};

export default SectionHeading;

const styles = {
  heading: {
    fontFamily: 'heading',
    '@media screen and (max-width: 768px)': {
      maxWidth:'100%',
     },
  },
  title: {
    color: 'heading',
    fontWeight: 'heading',
    fontSize: ['24px', '30px'],
    lineHeight: [1.2],
    letterSpacing: 'heading',
    textAlign: ['left', null, 'left', 'left'],
    '@media screen and (max-width: 768px)': {
      padding:'0'  
     },
  },
  description: {
    color: 'text',
    fontSize: ['14px', '17px'],
    lineHeight: [1.85, 2.0],
    fontWeight:'body',
    mt: '10px',
    textAlign: ['left', null, null, 'left'],
  },
  slogan: {
    color: 'heading',
    fontSize:['14px', '14px', '18px', '18px', '18px', '18px'],
    fontWeight: 'heading',
    lineHeight: 2.0,
    textAlign: ['left', null, 'left', 'left'],
  },
};
