/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Container, Box, Image} from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import SearchEngineOptimization from 'components/seo';

export default function About() {
  const data = useStaticQuery(graphql`
  {
    contentfulAbout {
      image{
        logoImage{
          file{
            url
            fileName
          }
        }
      } 
      textFeature {
        heading
        headingHighlight
        description {
          description
        }
      }
    }
  }
`)
  const image = data.contentfulAbout.image.logoImage.file;
  const text = data.contentfulAbout.textFeature;
  return (
    <React.Fragment> 
      <SearchEngineOptimization
        title="about"
        lang="en"/>
        <section sx={{ variant: 'section.about' }} id="about">
          <Container sx={styles.containerBox}>
            <Box sx={styles.contentWrapper}>
              <Box sx={styles.thumbnail}>
              <Image src={image.url} loading="lazy" alt="image.fileName" className="aboutImage" sx={styles.image}/>
              </Box>
              <Box sx={styles.contentBox}>
                <SectionHeading
                  sx={styles.heading}
                  slogan="About Us"
                  title={text.heading}
                  description={text.description.description} />
              </Box>
            </Box>
          </Container>
        </section>
     </React.Fragment>
  );
}

const styles = {
  containerBox:{
    paddingBottom:'4px',
  },
  contentWrapper: {
    gap: [20, 30, 30, 0, 45, 80, 80, 80],
    display: ['flex', 'flex', 'flex','flex', 'grid'],
    flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'unset'],
    gridTemplateColumns: [
      '1.2fr 0.8fr',
      '1.2fr 0.8fr',
      '1.2fr 0.8fr',
      '1.1fr 0.9fr',
      '1.1fr 0.9fr',
      '1.1fr 0.9fr',
      '1.2fr 0.8fr',
    ],
    alignItems: 'center',
  },
  thumbnail: {
    padding:'0',
    display: 'flex',
    width: ['100%', '100%', null, null, '100%', '100%', 670, '100%'],
    justifyContent: 'center',
    textAlign: ['center', null, null, null, 'right'],
    mr: [0, null, null, null, null, null, -6, -7],
    mb: [-2, null],
  },
  'image':{
    height:'auto',
    width:'inherit',
    borderRadius: '10px',
    // aspectRatio:'',
    '@media screen and (max-width: 768px)': {
      height:'400px',
      width:'100%'
     },
  },
  heading: {
    textAlign: 'left',
    mb: ['20px'],
    margin:'auto',
    h2: {
      fontSize: ['28px', '28px', '28px', '36px', '36px', '38px'],
      lineHeight: [1.2],
      letterSpacing: '-1.5px',
      br: {
        display: ['none', 'none', 'none', 'block'],
      },
    },
    p: {
      mt: ['15px', '10px'],
      // mb: ['20px'],

    },
  },
  contentBox: {
    flexShrink: 0,
    textAlign: ['left', null, 'left', 'left'],
    pb: [7, null, null, 0],
    '.description': {
      pr: [0, null, 7, 5],
    },
  },
  bottomArrow: {
    position: 'absolute',
    bottom: -185,
    left: '33%',
    display: ['none', null, null, null, null, null, null, 'block'],
  },
};
