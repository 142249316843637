/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Box, Heading, Text, Button, Image } from 'theme-ui';

export default function Banner() {
  const data = useStaticQuery(graphql`
  {
    contentfulBanner {
      navigationElement {
        name
        slug
      }
      image {
        logoImage {
          file {
            url
            fileName
          }
        }
      }
      textFeature {
        heading
        headingHighlight
        description {
         description
        }
      }
    }
  }
`)
  const button = data.contentfulBanner.navigationElement;
  const image = data.contentfulBanner.image.logoImage.file;
  const text = data.contentfulBanner.textFeature;

  return (
    <section sx={styles.banner} id="home">
      <Container sx={styles.banner.container}>
        <Box sx={styles.banner.contentBox}>
          <Heading as="h1" variant="heroPrimary" className="banner-title">
            {text.heading}
            <span className="standOutText">{text.headingHighlight}</span>
          </Heading>
          <Text as="p" variant="heroSecondary">
          {text.description.description}
          </Text>
          <Button as="a" href="#about" className="discover-Btn" >{button.name}</Button>
        </Box>
        <Box sx={styles.banner.imageBox}>
          <Image src={image.url} placeholder="blurred" alt={image.fileName} sx={styles.bannerImage} />
        </Box>
      </Container>
    </section>
  );
}
const styles = {
  banner: {
    overflow: ['hidden', 'initial', null, null, 'hidden'],
    backgroundColor:'#f2f9f4',
    pt: ['150px', '145px'],
    // pb: [0, null, null, null, 2],
    py: [8, null, 9, null, null, 10],
    container:{
      display:'flex',
      flexDirection:['column', null, null, null,'row'],
    },
    '.standOutText':{
        flexShrink: 0,
        mr: [15, 20, null, null, 0],
        ml: ['auto', null, null, null, 0],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: 'contain',
        backgroundColor: [null, null, null, null, 'transparent'],
        color: 'primary',
        fontWeight: 'bold',
        fontFamily: "body",
        fontSize: ['24px', 10, 11, 12, 11, 11, 11, 14],
    },
     '.discover-Btn':{
        width: 'inherit',
        fontFamily:'heading',
        margin:[null, null, 'initial', null, null, null, 'initial','initial'],
        '@media screen and (max-width: 768px)': {
          width:'auto'  
         },
      },
      contentBox: {
        width: ['100%', 430, 550, '75%', '50%', '40%'],
        display: 'initial',
        flexDirection: 'column',
        alignItems: 'flex-start',  
        fontFamily: 'heading',
        flexShrink: 0,
        pt: [0, null, null, null, null, 6, 7, '70px'],
        margin: '60px 0',
        marginBottom:'inherit',
        textAlign: ['center', null, null, null, 'left'],
        '.subscribe__area': {
          width: '100%',
          pr: [0, null, null, null, 6, '65px'],
          mb: ['35px', null, '45px'],
        },
        p:{
          color:'text'
        },
        '@media screen and (max-width: 768px)': {
          textAlign:'start'     
        },
        '.banner-title':{
          paddingLeft:'0'
        },
      },
      imageBox: {
        justifyContent: 'center',
        textAlign: ['center', null, null, null, 'right'],
        mr: [0, null, null, null, null, null, -6, -7],
        pl: [0, null, '40px'],
        mb: [-2, null],
        width: [330, '100%', null, null, 480, '60%', 670, 760],
        img: {
          height: [360, 'auto'],
        },
          '@media screen and (max-width: 320px)': {
              display:'none' 
          },
      },
  },
  bannerImage:{
    width:'750px'
  },
  sponsorTitle: {
    fontSize: 2,
    color: '#676A8B',
    pr: [0, null, 4],
    flexShrink: 0,
    pb: [2, null, 0],
    mt: '-1px',
  },
  sponsorBox: {
    flexDirection: ['column', null, 'row'],
    width: '100%',
    justifyContent: ['flex-start', null, 'center', null, 'flex-start'],
    sponsor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '> a': {
        mr: [4, null, 6],
        display: 'flex',
        '&:last-of-type': {
          mr: 0,
        },
      },
    },
  },
};
