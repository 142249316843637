import React from 'react';
import 'rc-drawer/assets/index.css';
import About from '../sections/about';
import Layout from 'components/layout';
import Banner from '../sections/banner';
import Feature from '../sections/feature';
import Gallery from '../sections/gallery';
import { StickyProvider } from '../contexts/app/app.provider';

export default function IndexPage() {
  return (
      <StickyProvider>
        <Layout>
          <Banner />
          <About />
          <Feature />
          <Gallery />
        </Layout>
      </StickyProvider>
  );
}
 